import {CookieBrowser} from "@core/service/cookie/browser";
import {CookieInject} from "@core/service/cookie/inject";
import {APP_INITIALIZER, NgModule} from "@angular/core";
import {AppComponent} from "./app.component";
import {AppModule} from "./app.module";
import {REQUEST} from "@common/tokens/express.tokens";
import {RedirectInject} from "@core/service/redirect/inject";
import {RedirectBrowserService} from "@core/service/redirect/browser";

import {AngularSvgIconModule, SvgLoader} from "angular-svg-icon";
import {HttpClient} from "@angular/common/http";
import {svgLoaderBrowserFactory} from "@core/service/svg-icon/svg-browser-factory.loader";
import {ORIGIN_HEADERS, ORIGIN_HREF, ORIGIN_URL, ORIGIN_USER_AGENT} from "@shared/injection-token/tokens";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ActiveOrdersService} from "@core/service/active-orders/active-orders.service";
import {DialogModule} from "@angular/cdk/dialog";
import {CONFIG_BROWSER} from "./app.config.browser";
import {ServiceWorkerModule} from "@angular/service-worker";

export function getRequest(): any {
    return {headers: {cookie: document.cookie}};
}

const disableAnimations =
    !("animate" in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
    bootstrap: [AppComponent],
    imports: [
        AppModule,
        DialogModule,
        BrowserAnimationsModule.withConfig({disableAnimations}),
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: true,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000",
        }),
        AngularSvgIconModule.forRoot({
            loader: {
                provide: SvgLoader,
                useFactory: svgLoaderBrowserFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        ...CONFIG_BROWSER,
        {
            provide: REQUEST,
            useFactory: getRequest,
        },
        {provide: RedirectInject, useClass: RedirectBrowserService},
        {provide: CookieInject, useClass: CookieBrowser},
        {
            provide: ORIGIN_URL,
            useValue: location.origin,
        },
        {
            provide: ORIGIN_HEADERS,
            useValue: null,
        },
        {
            provide: ORIGIN_HREF,
            useValue: location.href,
        },
        {
            provide: ORIGIN_USER_AGENT,
            useValue: window.navigator.userAgent,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (activeOrdersService: ActiveOrdersService) => () => activeOrdersService.load(),
            deps: [ActiveOrdersService],
            multi: true,
        },
    ],
})
export class BrowserAppModule {}
