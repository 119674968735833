import {DialogConfig} from "@angular/cdk/dialog";
import {AddCartWarning} from "@core/model/add-cart-warning";

export const DIALOG_UI_WARNING_RECIPE_CONFIG: DialogConfig = {
    closeOnNavigation: true,
    panelClass: ["dialog-warning", "cdk-overlay-pane-new"],
    data: <DialogWarningRecipeData>{},
    maxWidth: "550px",
};

export interface DialogWarningRecipeData {
    warning: AddCartWarning;
    closesPermanently: boolean;
}
