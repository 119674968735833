import {inject, Injectable} from "@angular/core";
import {Platform} from "@core/service/platform";
import {YandexMetrikaService} from "@core/service/yandex-metrika";
import {CityService} from "@core/service/city";
import {skip, take} from "rxjs/operators";
import {TalkMeService} from "@shared/service/talk-me/talk-me";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {ApiAuthTelemetryService, AuthTelemetryInPut} from "@common/shared/service/api/auth/telemetry";

@Injectable({providedIn: "root"})
export class TelemetryService {
    private deviceToken: string = "";
    private apiAuthTelemetryService = inject(ApiAuthTelemetryService);

    constructor(
        private _platform: Platform,
        private _city: CityService,
        private _yandexMetrika: YandexMetrikaService,
        private _talkMeService: TalkMeService,
        private _deviceToken: DeviceTokenService,
    ) {
        this._city.city$.pipe(skip(1)).subscribe(() => {
            this.switchCity();
        });
    }

    // После загрузки страницы первого раза
    identification() {
        this.deviceToken = this._deviceToken.get();

        if (this._platform.server) {
            this.apiAuthTelemetryService.put({device_token: this.deviceToken});
        } else {
            this._yandexMetrika
                .getClientID()
                .pipe(take(1))
                .subscribe((id) => {
                    const yaObj: AuthTelemetryInPut = {
                        device_token: this.deviceToken,
                    };
                    if (id) {
                        yaObj.yandexmetrica_id = id;
                    }

                    this.apiAuthTelemetryService.put(yaObj);
                    this._yandexMetrika.setUserID(this.deviceToken);
                    this._talkMeService.setUserID(this.deviceToken);
                });
        }
    }

    // При смене города
    switchCity() {
        this.deviceToken = this._deviceToken.get();
        this._yandexMetrika
            .getClientID()
            .pipe(take(1))
            .subscribe((id) => {
                this.apiAuthTelemetryService.put({device_token: this.deviceToken, yandexmetrica_id: id});
            });
    }

    // При авторизации/регистрации
    auth() {
        this.deviceToken = this._deviceToken.get();
        this._yandexMetrika
            .getClientID()
            .pipe(take(1))
            .subscribe((id) => {
                this.apiAuthTelemetryService.put({device_token: this.deviceToken, yandexmetrica_id: id});
            });
    }
}
