import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    HostListener,
    inject,
    Input,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
    WritableSignal,
} from "@angular/core";
import {fromEvent, Observable, of, Subject, Subscription, switchMap, tap, timer} from "rxjs";
import {debounceTime, filter, map, takeUntil} from "rxjs/operators";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {UiSearchHeaderService} from "@shared/module_v2/ui/search-header/search-header.service";
import {Platform} from "@core/service/platform";
import {YandexMetrikaService} from "@core/service/yandex-metrika";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {AngularSvgIconModule} from "angular-svg-icon";
import {ButtonGeneralModule} from "@component/button/general/general.module";
import {SearchHeaderHintsComponent} from "@component/ui-request/search-header-hints/search-header-hints.component";
import {SearchHeaderHintsService} from "@component/ui-request/search-header-hints/search-header-hints.service";
import {ParametersService} from "@core/service/parameters";
import {toObservable} from "@angular/core/rxjs-interop";

@Component({
    selector: "ma-ui-search-header",
    standalone: true,
    templateUrl: "./search-header.component.html",
    styleUrls: ["./search-header.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AngularSvgIconModule, ButtonGeneralModule, SearchHeaderHintsComponent, ReactiveFormsModule],
})
export class SearchHeaderComponent implements OnInit, OnDestroy {
    private yandexMetrikaService = inject(YandexMetrikaService);
    private uiSearchHeaderService = inject(UiSearchHeaderService);
    private parametersService = inject(ParametersService);
    private searchHeaderHintsService = inject(SearchHeaderHintsService);
    private platform = inject(Platform);

    @Input() isFloating: boolean = false;
    public isViewHints: WritableSignal<boolean> = signal<boolean>(false);
    public hintsStatus = computed(() => this.searchHeaderHintsService.data().status);

    public isMouseOver: boolean = false;

    private routerEvents$: Subscription = new Subscription();
    private windowScroll$: Subscription = new Subscription();
    private activatedRouteQueryParams$: Subscription = new Subscription();

    public queryControl: FormControl = new FormControl("");
    private cancelQuery$ = new Subject<void>();
    private querySubscribe$: Subscription;
    private querySignalSubscribe$: Subscription;
    private querySignal$: Observable<string> = toObservable(this.uiSearchHeaderService.query);

    @HostListener("mouseenter", ["$event"]) onMouseEnter() {
        this.isMouseOver = true;
    }

    @HostListener("mouseleave", ["$event"]) onMouseLeave() {
        this.isMouseOver = false;
    }

    @ViewChild("input", {static: false}) input: ElementRef<HTMLInputElement>;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.initSubs();
        this.initValue();
    }

    ngOnDestroy() {
        this.routerEvents$.unsubscribe();
        this.activatedRouteQueryParams$.unsubscribe();
        this.windowScroll$.unsubscribe();
        this.cancelQuery$.next();
        this.cancelQuery$.complete();
        if (this.querySubscribe$) {
            this.querySubscribe$.unsubscribe();
        }
        if (this.querySignalSubscribe$) {
            this.querySignalSubscribe$.unsubscribe();
        }
    }

    initValue() {
        const paramMap = this._activatedRoute.snapshot.queryParamMap;
        if (paramMap.has("q")) {
            this.uiSearchHeaderService.query.set(paramMap.get("q"));
        }
    }

    initSubs() {
        this.routerEvents$ = this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
            this.isViewHints.set(false);
        });
        this.activatedRouteQueryParams$ = this._activatedRoute.queryParams.subscribe(() => {
            this.initValue();
        });
        this.querySignalSubscribe$ = this.querySignal$.subscribe((newQuery) => {
            if (this.queryControl.value !== newQuery) {
                this.queryControl.setValue(newQuery, {emitEvent: false});
            }
        });
        this.querySubscribe$ = this.queryControl.valueChanges
            .pipe(
                tap((value) => {
                    this.isViewHints.set(true);
                    if (value !== this.uiSearchHeaderService.query()) {
                        this.uiSearchHeaderService.query.set(value);
                    }
                }),
                switchMap((value) =>
                    value?.length > 2
                        ? timer(this.parametersService.parametersData.DELAY_SEARCH_SUGGESTS).pipe(
                              map(() => value),
                              takeUntil(this.cancelQuery$),
                          )
                        : of(value),
                ),
            )
            .subscribe((value) => {
                this.searchHeaderHintsService.changedQuery(value);
            });

        if (this.platform.browser) {
            this.windowScroll$ = fromEvent(window, "scroll")
                .pipe(
                    debounceTime(60),
                    filter(() => {
                        return this.isViewHints() === true;
                    }),
                )
                .subscribe(() => {
                    this.isViewHints.set(false);
                });
        }
    }

    onChangedQuery(query: string | null) {
        this.cancelQuery$.next();
        this.uiSearchHeaderService.query.set(query);
        this.searchHeaderHintsService.changedQuery(query);
    }

    onClickQuery() {
        const query = this.queryControl.value;
        if (query?.length > 2) {
            this.isViewHints.set(false);
            // #51966 > Подтверждение ручного ввода текста (Поиск_товаров)
            this.yandexMetrikaService.reachGoal("GL_action_search", {
                type: "Ручной ввод текста",
                text: query,
            });
            this._router.navigate(["/search"], {queryParams: {q: query}});
        }
    }

    onClickDelete() {
        this.input.nativeElement.focus();
        this.onChangedQuery(null);
    }

    onInputBlur() {
        if (this.isMouseOver === false) {
            this.isViewHints.set(false);
        }
    }

    onInputFocus() {
        if (this.hintsStatus() === "start") {
            const query = this.queryControl.value;
            if (query?.length > 2) {
                this.searchHeaderHintsService.getQueriesAndHistory(false);
                this.searchHeaderHintsService.changedQuery(query);
            } else {
                this.searchHeaderHintsService.getQueriesAndHistory();
            }
        }
        this.isViewHints.set(true);
        // #51966 > Нажатие на поле ввода/ голос/ штрих-код (Переход_в_поиск_товаров)
        this.yandexMetrikaService.reachGoal("GL_focus_search");
    }

    onSearchValue(query: string) {
        this.isViewHints.set(false);
        this.onChangedQuery(query);
    }
}
