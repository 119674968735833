import {inject, Injectable} from "@angular/core";
import {Cookie} from "../cookie";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {Subject} from "rxjs/internal/Subject";
import {Platform} from "@core/service/platform";
import {LsService} from "@core/service/localstorage";
import {UserData, UserStorage} from "./interface";
import {AuthLogoutService} from "@shared/service/api/auth/logout";
import {catchError, map, mergeMap} from "rxjs/operators";
import {ClientsService} from "@shared/service/api/clients";
import {ApiAuthCheckService} from "@common/shared/service/api/auth/check";
import {Observable, of, throwError} from "rxjs";

@Injectable({providedIn: "root"})
export class UserService {
    private nameAuthToken = "auth_token";
    private nameVariableStorage = "ma_user2";
    private dataDefault: UserData = {
        name: "",
        phone: "",
        email: "",
    };
    public isAuth: boolean = false;

    public data$: BehaviorSubject<UserData> = new BehaviorSubject<UserData>(this.dataDefault);
    public resultCheck: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public register$: Subject<boolean> = new Subject<boolean>();
    public login$: Subject<boolean> = new Subject<boolean>();
    public logout$: Subject<boolean> = new Subject<boolean>();
    public isAuth$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private authLogoutService = inject(AuthLogoutService);
    private clientsService = inject(ClientsService);
    private apiAuthCheckService = inject(ApiAuthCheckService);
    /**
     * Статус загрузки авторизации, вызывается только один раз при загрузке приложения в app-initializer.
     */
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private _cookie: Cookie,
        private _ls: LsService,
        private _lsService: LsService,
        private _platform: Platform,
    ) {}

    public run() {
        this.login$.subscribe(() => {
            this.get();
        });
        this.logout$.subscribe(() => {
            this.data$.next(this.dataDefault);
        });
        this.get().then(() => {
            this.loading$.next(true);
        });
    }

    /**
     * Выход
     *
     * @returns void
     **/
    logout() {
        return this.authLogoutService.put().pipe(
            mergeMap(() => {
                return this.check();
            }),
            map((res) => {
                if (res === false) {
                    this.logout$.next(true);
                    this.isAuth$.next(false);
                    this._lsService.remove("ma_user");
                    this._cookie.removeItem(this.nameAuthToken);
                }
                return;
            }),
        );
    }

    /**
     * Запись auth token
     *
     * @param {string} token
     */
    setToken(token: string): void {
        if (token.length === 64) {
            this._cookie.setItem(this.nameAuthToken, token);
        }
    }

    /**
     * Возращает токен
     *
     * @returns {string}
     */
    getToken(): string {
        return <string>this._cookie.getItem(this.nameAuthToken);
    }

    /**
     *   Проверка токена
     * @returns {Observable<boolean>}
     */
    check(): Observable<boolean> {
        if (this._platform.browser) {
            if (this.getToken()) {
                return this.apiAuthCheckService.post().pipe(
                    map(({result}) => {
                        this.resultCheck.next(result);

                        if (result === false) {
                            this._cookie.removeItem(this.nameAuthToken);
                        } else {
                            const token = this._cookie.getItem(this.nameAuthToken);
                            this._cookie.setItem(this.nameAuthToken, token);
                        }
                        this.isAuth = result;
                        this.isAuth$.next(this.isAuth);
                        return result;
                    }),
                    catchError((err) => {
                        this.resultCheck.next(false);
                        this.isAuth = false;
                        this.isAuth$.next(this.isAuth);
                        return throwError(() => err);
                    }),
                );
            }
        }
        return of(false).pipe(
            map(() => {
                this.isAuth = false;
                this.isAuth$.next(this.isAuth);
                return false;
            }),
        );
    }

    /**
     * Адрес магазина
     *
     * @returns {number}
     */
    get store(): number {
        const idStore = this._ls.get("store");
        if (+idStore > 0) {
            return <number>+idStore;
        }
        return 0;
    }

    set store(id: number) {
        this._ls.set("store", id);
    }

    /**
     * Запрос пользователя
     *
     * @returns {Promise<ClientsOutGet | boolean>}
     */
    public get(): Promise<any> {
        return this.check()
            .pipe(
                map((res) => {
                    if (res) {
                        return this.clientsService.get().subscribe({
                            next: (res) => {
                                this.data$.next(res);
                            },
                            error: () => {
                                this.data$.next(this.dataDefault);
                            },
                        });
                    } else {
                        this.data$.next(this.dataDefault);
                    }
                    return false;
                }),
            )
            .toPromise();
    }

    /**
     * Получение данных пользователя из Storage
     *
     * @return UserStorage | null
     */
    get storage(): UserStorage | null {
        let res = null;
        const str = this._ls.get(this.nameVariableStorage);
        if (str) {
            try {
                if (str) {
                    res = JSON.parse(str);
                }
            } catch {
                res = null;
            }
        }

        return res;
    }

    /**
     * Запись данных пользователя в Storage
     *
     * @param data <UserStorage>
     */
    set storage(data: UserStorage | string) {
        this._ls.remove("ma_user");
        this._ls.set(this.nameVariableStorage, JSON.stringify(data));
    }
}
