import {inject, Injectable, Injector} from "@angular/core";
import {HttpHandler, HttpHeaders, HttpInterceptor as HttpInterceptorCore, HttpParams, HttpRequest} from "@angular/common/http";
import {Config} from "@core/service/config";
import {LogService} from "@core/service/logger";
import {Platform} from "@core/service/platform";
import {REQUEST} from "@common/tokens/express.tokens";
import HmacSHA256 from "crypto-js/hmac-sha256";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {Cookie} from "@core/service/cookie";
import {ORIGIN_USER_AGENT} from "@shared/injection-token/tokens";

interface Data {
    [key: string]: string | number | boolean | Blob | File | Data | null | undefined;
}

interface searchFileResult {
    file: {
        path: string[];
        file: File | Blob;
    }[];
    data: Data;
}

@Injectable()
export class HttpInterceptor implements HttpInterceptorCore {
    private _logger: LogService;
    private _config: Config;

    private _platformService: Platform;
    private _request: any;
    private _cookieService: Cookie;
    private _deviceTokenService: DeviceTokenService;

    private data: any;
    private userAgent = inject(ORIGIN_USER_AGENT);

    constructor(private _injector: Injector) {
        this._logger = _injector.get(LogService);
        this._config = _injector.get(Config);
        this._platformService = _injector.get(Platform);
        this._request = _injector.get(REQUEST);
        this._cookieService = _injector.get(Cookie);
        this._deviceTokenService = _injector.get(DeviceTokenService);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Если запрос к статическим файлам или внешним ресурсам, то пропускаем
        if (/^(\.*\/*assets|https:|http:)/i.test(req.url)) {
            return next.handle(req);
        }
        // Для старой библиотеки апи
        if (req.headers.get("token") !== null) {
            return next.handle(req);
        }
        if (req.method === "GET") {
            this.data = this.params(req.params);
            const headers = this.updateHeader(req.headers);
            const url = this.createUrl(req.url);

            const secureReq = req.clone({
                url: url,
                setHeaders: headers,
                setParams: {"data": this.data, "ngsw-bypass": "true"},
            });
            return next.handle(secureReq);
        }

        if (req.method === "PUT" || req.method === "POST") {
            this.data = this.params(req.body);
            let cloneReq = req.clone({
                url: this.createUrl(req.url),
                setHeaders: this.updateHeader(req.headers),
            });

            const findFile = this.searchFile(req.body as Data, null, [], {});
            if (findFile.file.length > 0) {
                const formData = new FormData();
                findFile.file.forEach((v) => {
                    formData.set(v.path.join("."), v.file || "");
                });
                this.data = this.params(findFile.data);
                formData.set("data", this.data);
                cloneReq = cloneReq.clone({
                    body: formData,
                    setHeaders: this.updateHeader(req.headers),
                });
            }
            return next.handle(cloneReq);
        }

        return next.handle(req);
    }

    /**
     * Поиск файлов в запросе
     *
     * @param obj
     * @param result
     * @param previous
     * @param data
     */
    private searchFile(obj: Data, result: searchFileResult | null, previous: string[], data: Data): searchFileResult {
        if (result === null) {
            result = {
                file: [],
                data: {},
            };
        }
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (obj[key] instanceof Blob || obj[key] instanceof File) {
                    let file: File | Blob | undefined;
                    if (obj[key] instanceof File) {
                        file = obj[key] as File;
                    }
                    if (obj[key] instanceof Blob) {
                        file = obj[key] as Blob;
                    }
                    if (file !== undefined) {
                        result.file.push({
                            path: [...previous, key],
                            file: file,
                        });
                    }
                } else if (obj[key] !== null && typeof obj[key] === "object") {
                    if (!data[key]) {
                        data[key] = {};
                    }
                    this.searchFile(obj[key] as Data, result, [...previous, key], data[key] as Data);
                } else {
                    data[key] = obj[key];
                }
            }
        }
        result.data = data;
        return result;
    }

    /**
     * Подготовка данных
     *
     * @param params обьект с данными
     *
     * @returns {string}
     */
    private params(params: HttpParams | Data): string {
        if (params instanceof HttpParams) {
            const keys = params.keys();
            const obj: Record<string, string | null> = {};

            if (params.has("data")) {
                return params.get("data") as string;
            } else {
                keys.forEach((key: string) => {
                    if (params.has(key)) {
                        obj[key] = params.get(key);
                    }
                });
                return <string>JSON.stringify(obj);
            }
        }
        return <string>JSON.stringify(params);
    }

    /**
     * Именяем header
     *
     * @param _headers
     * @returns {Headers}
     */
    private updateHeader(_headers: HttpHeaders) {
        const updateHeaders: any = {};
        const configHeader = this._config.get("api")["header"];

        /**
         * Конфиг
         */
        Object.keys(configHeader).forEach((key) => {
            updateHeaders[<string>key] = configHeader[key].toString();
        });

        /**
         * Входящие параметры
         */
        if (_headers && _headers.keys().length > 0) {
            Object.keys(_headers).forEach((key) => {
                updateHeaders[<string>key] = _headers[key];
            });
        }

        /**
         * Токен
         */
        updateHeaders["token"] = this.createToken();

        const authToken = this._cookieService.getItem("auth_token") ? this._cookieService.getItem("auth_token") : "";
        if (authToken.length > 0) {
            updateHeaders["auth_token"] = authToken;
            updateHeaders["Auth-Token"] = authToken;
        }

        if (this._platformService.server) {
            updateHeaders["x-forwarded-for"] = this._request["header"]["x-forwarded-for"];
            updateHeaders["User-Agent"] = this.userAgent;

            if (this._request["headers"]["ma-action-id"]) {
                updateHeaders["ma-action-id"] = this._request["headers"]["ma-action-id"];
            }
            if (this._request["headers"]["x-qrator-ip-source"]) {
                updateHeaders["x-qrator-ip-source"] = this._request["headers"]["x-qrator-ip-source"];
            }
        }
        updateHeaders["Device-Token"] = this._deviceTokenService.get();
        return updateHeaders;
    }

    /**
     * Создания токена
     *
     * @private
     */
    private createToken(): string {
        const api = this._config.get("api");
        const secret = <string>api["secret"];

        return <string>HmacSHA256(this.data, secret).toString().toUpperCase();
    }

    /**
     * Создания url
     *
     * @param path
     * @returns {string}
     */
    private createUrl(path: string): string {
        let urlPath = "";
        const api = this._config.get("api");
        const port = api["port"];
        const protocol = api["protocol"];
        const host = api["url"];
        const queryParams = api["queryParams"] || null;

        urlPath += protocol + "://";
        urlPath += host;
        if (port) {
            urlPath += ":" + port;
        }
        urlPath += `/${path}`;

        const url = new URL(urlPath);

        if (Array.isArray(queryParams)) {
            queryParams.forEach((item) => {
                url.searchParams.set(item.key, item.value);
            });
        }

        return url.toString();
    }
}
