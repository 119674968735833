import {ErrorHandler} from "@angular/core";
import {ErrorHandlerService} from "@common/core/handler/error";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";

/**
 * Только для общих сервисов которые нужно загрузить при инициализации приложения.
 */
export const CONFIG_CORE = [
    {
        provide: ErrorHandler,
        useClass: ErrorHandlerService,
    },
    provideHttpClient(withInterceptorsFromDi()),
];
