import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {filter, takeUntil, tap} from "rxjs/operators";

@Injectable()
export class HttpCancelRepeatInterceptor implements HttpInterceptor {
    private cache = new Map<string, Subject<void>>();

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.method !== "GET") {
            return next.handle(request);
        }
        if (request.url === "ma/site/v2/groups") {
            return next.handle(request);
        }
        if (request.url === "ma/site/v1/cities") {
            return next.handle(request);
        }
        if (request.url === "ma/site/v4/items/type-analogs") {
            return next.handle(request);
        }
        // Для старой библиотеки апи
        if (request.headers.get("token") !== null) {
            return next.handle(request);
        }
        // Если запрос к статическим файлам или внешним ресурсам, то пропускаем
        if (/^(\.*\/*assets|https:|http:)/i.test(request.url)) {
            return next.handle(request);
        }

        const url = request.url;
        const cachedResponse = this.cache.get(url);
        if (cachedResponse) {
            cachedResponse.next();
        }
        const cancelRequests$ = new Subject<void>();
        this.cache.set(url, cancelRequests$);

        return next.handle(request).pipe(
            takeUntil(cancelRequests$),
            filter((event) => event instanceof HttpResponse),
            tap(() => {
                this.cache.delete(url);
            }),
        );
    }
}
