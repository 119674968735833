import {NgModule} from "@angular/core";
import {RouterModule, Routes, UrlMatchResult, UrlSegment} from "@angular/router";
import {MainComponent} from "./layout/main/main.component";

export function PageMatcherCatalog(url: UrlSegment[]): UrlMatchResult {
    if (url.length === 0) {
        return null;
    }

    // Пробуем разобрать урл
    if (url[1]) {
        const urlString = decodeURIComponent(url[1].toString()).replace(/\?.*$/gi, "");
        const posParams = {};
        const regPage = /^[0-9]+$/;
        const regTag = /^tag_/gi;
        // Номер страницы если нет фильтра
        if (urlString.match(regPage)) {
            posParams["page"] = url[1];
            // Если стоит фильтр
        } else if (regTag.test(urlString)) {
            posParams["tag"] = Object.assign({}, url[1]);
            posParams["tag"].path = posParams["tag"].path.replace(/^tag_/, "");

            // Номер страницы если есть фильтер
            if (url[2]) {
                const urlStringPage = decodeURIComponent(url[2].toString()).replace(/\?.*$/gi, "");

                if (urlStringPage.match(regPage)) {
                    url[2].path = urlStringPage;
                    posParams["page"] = url[2];
                }
            }
        }

        posParams["group_code"] = url[0];
        if (!posParams["tag"] && !posParams["page"]) {
            return null;
        }

        return {
            consumed: url,
            posParams: posParams,
        };
    }

    return null;
}

export function product(url: UrlSegment[]): UrlMatchResult {
    const posParams = {};
    if (url.length === 0) {
        return null;
    }
    if (url.length < 2) {
        return null;
    }

    if (url.length === 2) {
        if (url[0].path !== "product" && url[1].path) {
            posParams["city"] = url[0];
            posParams["product_code"] = url[1];
            return {
                consumed: [url[0]],
                posParams: posParams,
            };
        }
    } else if (url.length === 3) {
        if (url[2].path === "reviews" || url[2].path === "analogues") {
            posParams["product_code"] = url[1];
            if (url[0].path !== "product") {
                posParams["city"] = url[0];
            }

            return {
                consumed: [url[0]],
                posParams: posParams,
            };
        }
        posParams["city"] = url[0];
        posParams["product_code"] = url[1];
        return {
            consumed: [url[0]],
            posParams: posParams,
        };
    }

    return null;
}

export const routes: Routes = [
    {
        path: "pharmacist-quiz",
        loadComponent: () => import("./layout/quizzes/quizzes.component").then((m) => m.QuizzesComponent),
        children: [{path: "", loadChildren: () => import("./page/quizzes/quizzes.routing").then((m) => m.QUIZZES_ROUTES)}],
    },
    {
        path: "pharmacies",
        loadComponent: () => import("./layout/quizzes/quizzes.component").then((m) => m.QuizzesComponent),
        children: [{path: "", loadChildren: () => import("./page/pharmacies/pharmacies.routing").then((m) => m.PHARMACIES_ROUTES)}],
    },

    {
        path: "basket/order",
        loadComponent: () => import("./layout/basket/basket.component").then((m) => m.BasketComponent),
        children: [
            {
                path: "",
                loadChildren: () => import("./page/basket/order/order.module").then((m) => m.PageBasketOrderModule),
            },
        ],
    },
    {
        path: "basket/on-order",
        loadComponent: () => import("./layout/basket/basket.component").then((m) => m.BasketComponent),
        children: [
            {
                path: "",
                loadChildren: () => import("./page/basket/on-order/on-order.module").then((m) => m.OnOrderModule),
            },
        ],
    },
    {
        path: "basket/order-multi",
        loadComponent: () => import("./layout/empty/empty.component").then((m) => m.EmptyComponent),
        children: [
            {
                path: "",
                loadChildren: () => import("./page/basket/order-multi/order-multi.routing").then((m) => m.ORDER_MULTI_ROUTES),
            },
        ],
    },
    {
        path: "geo",
        loadComponent: () => import("./layout/geo/geo.component").then((m) => m.GeoComponent),
        children: [
            {
                path: "",
                loadChildren: () => import("./page/geo/geo.routing").then((m) => m.GEO_ROUTES),
            },
        ],
    },
    {
        path: ":city/geo",
        loadComponent: () => import("./layout/geo/geo.component").then((m) => m.GeoComponent),
        children: [
            {
                path: "",
                loadChildren: () => import("./page/geo/geo-city.routing").then((m) => m.GEO_CITY_ROUTES),
            },
        ],
    },
    {
        path: "",
        component: MainComponent,
        children: [
            {
                path: "basket/success",
                loadChildren: () => import("./page/basket/success/success.routing").then((m) => m.SUCCESS_ROUTES),
            },
            {path: "basket", loadChildren: () => import("./page/basket/index/index.module").then((m) => m.IndexModule)},
            {path: "presentation", loadChildren: () => import("./page/presentation/module").then((m) => m.PresentationAppsModule)},
            {path: "301", loadComponent: () => import("./page/301/301.component").then((m) => m.Redirect301Component)},
            {
                path: "check",
                loadChildren: () => import("./page/order-check/order-check.routing").then((m) => m.ORDER_CHECK_ROUTES),
            },
            {path: "registry", loadChildren: () => import("./page/registry/registry.module").then((m) => m.RegistryModule)},
            {path: "help", loadChildren: () => import("./page/help/help.module").then((m) => m.PageHelpModule)},
            {path: "about/partners", loadChildren: () => import("./page/about/partners/module").then((m) => m.PartnersModule)},
            {
                path: "about",
                loadComponent: () => import("./page/about/about.component").then((m) => m.PageAboutComponent),
                children: [{path: "", loadChildren: () => import("./page/about/about.routing").then((m) => m.ABOUT_ROUTES)}],
            },
            {path: "terms", loadChildren: () => import("./page/terms/terms.routing").then((m) => m.TERMS_ROUTES)},
            {
                path: "agreements",
                loadChildren: () => import("./page/agreements/agreements.routing").then((m) => m.AGREEMENTS_ROUTES),
            },
            {
                path: "recommendation-technologies",
                loadChildren: () =>
                    import("./page/recommendation-technologies/recommendation-technologies.routes").then(
                        (mod) => mod.RECOMMENDATION_TECHNOLOGIES_ROUTES,
                    ),
            },
            {path: "unsub", loadChildren: () => import("./page/unsub/module").then((m) => m.PageUnsubModule)},
            {path: "search", loadChildren: () => import("./page/search/search.module").then((m) => m.SearchModule)},
            {path: "ai-items", loadChildren: () => import("./page/ai-items/ai-items.module").then((m) => m.AiItemsModule)},
            {path: "auth", loadChildren: () => import("./page/auth/auth.routing").then((m) => m.AUTH_ROUTES)},
            {path: "oauth", loadChildren: () => import("./page/oauth/oauth.routing").then((m) => m.OAUTH_ROUTES)},
            {path: "lk", loadChildren: () => import("./page/lk/lk.routing").then((m) => m.LK_ROUTES)},
            {path: "feedback", loadChildren: () => import("./page/feedback/module").then((m) => m.PageFeedbackModule)},
            {path: "subscription", loadChildren: () => import("./page/subscription/module").then((m) => m.SubscriptionModule)},
            {
                path: "specials/:specials_code",
                loadChildren: () => import("./page/specials/detail/detail.module").then((m) => m.DetailModule),
            },
            {
                path: "specials/author/:code",
                loadChildren: () => import("./page/specials/author/module").then((m) => m.SpecialsAuthorListModule),
            },
            {path: "specials", loadChildren: () => import("./page/specials/list/list.module").then((m) => m.ListModule)},
            {
                path: "megapharmacist/:specials_code",
                loadComponent: () => import("./page/megapharmacist/detail/detail.component").then((m) => m.MegapharmacistDetailComponent),
            },
            {path: "megapharmacist", loadChildren: () => import("./page/megapharmacist/list/list.module").then((m) => m.ListModule)},
            {path: "articles", loadChildren: () => import("./page/articles/articles.module").then((m) => m.ArticlesModule)},
            {
                path: "sitemap-html",
                loadChildren: () => import("./page/sitemap-html/sitemap-html.routing").then((m) => m.SITEMAP_HTML_ROUTES),
            },
            {
                path: "products/alphabet",
                loadChildren: () => import("./page/product/alphabet/alphabet.routing").then((m) => m.ALPHABET_ROUTES),
            },
            {path: "404", loadComponent: () => import("./page/404/404.component").then((m) => m.NotFoundComponent)},
            {path: "410", loadComponent: () => import("./page/410/410.component").then((m) => m.NotFoundComponent)},
            {path: "feedback_policy", loadChildren: () => import("./page/feedback_policy/module").then((m) => m.PageFeedbackPolicyModule)},
            {path: "advices", loadChildren: () => import("./page/advices/advices.module").then((m) => m.AdvicesPageModule)},
            {
                path: "catalog",
                loadChildren: () => import("./page/catalog-sitemap/catalog-sitemap.routing").then((m) => m.CATALOG_SITEMAP_ROUTES),
            },
            {path: "orders", loadChildren: () => import("./page/orders/orders.routes").then((mod) => mod.ORDERS_ROUTES)},
            {path: "items/share", loadChildren: () => import("./page/items/share/share.routes").then((m) => m.SHARE_ROUTES)},
            {
                path: "tags/:group_code/:tag",
                loadChildren: () => import("./page/catalog/handler-section/handler-section.routing").then((m) => m.SECTION_ROUTES),
            },
            {
                path: "return",
                loadComponent: () => import("./page/return/return.component").then((m) => m.ReturnComponent),
            },
            {
                path: ":city/sitemap-html",
                loadChildren: () => import("./page/sitemap-html/sitemap-html-city.routing").then((m) => m.SITEMAP_HTML_CITY_ROUTES),
            },
            {
                path: ":city/catalog",
                children: [
                    {
                        matcher: PageMatcherCatalog,
                        runGuardsAndResolvers: "paramsChange",
                        loadChildren: () => import("./page/catalog/handler-section/handler-section.routing").then((m) => m.SECTION_ROUTES),
                    },
                    {
                        path: ":group_code/:item_code",
                        loadChildren: () => import("./page/catalog/detail/detail.module").then((m) => m.DetailModule),
                    },
                    {
                        path: ":group_code",
                        loadChildren: () => import("./page/catalog/handler-section/handler-section.routing").then((m) => m.SECTION_ROUTES),
                    },
                    {
                        path: "",
                        loadChildren: () => import("./page/catalog/handler-section/handler-section.routing").then((m) => m.SECTION_ROUTES),
                    },
                ],
            },
            {
                path: ":city",
                pathMatch: "full",
                loadComponent: () => import("./page/main/main.component").then((m) => m.MainComponent),
            },
            {
                data: {
                    path: "product",
                },
                matcher: product,
                loadChildren: () => import("./page/product/product.routing").then((m) => m.PRODUCT_ROUTES),
            },
            {
                path: "",
                pathMatch: "full",
                loadComponent: () => import("./page/main/main.component").then((m) => m.MainComponent),
            },
        ],
    },
    {path: "**", loadComponent: () => import("./page/404/404.component").then((m) => m.NotFoundComponent)},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: "enabledBlocking",
            scrollPositionRestoration: "enabled",
            anchorScrolling: "enabled",
            scrollOffset: [0, 90],
            onSameUrlNavigation: "reload",
            paramsInheritanceStrategy: "always",
            enableTracing: false,
        }),
    ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
